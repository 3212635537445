export const ArrowDownIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 8L10 14L4 8" stroke="#131E29" strokeLinecap="round" />
  </svg>
);

export const ClearIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 8C14.4 11.5346 11.5346 14.4 7.99998 14.4C4.46535 14.4 1.59998 11.5346 1.59998 8C1.59998 4.46538 4.46535 1.6 7.99998 1.6C11.5346 1.6 14.4 4.46538 14.4 8ZM4.89282 4.89289C5.28334 4.50237 5.91651 4.50237 6.30703 4.89289L7.99998 6.58584L9.69292 4.89289C10.0834 4.50237 10.7166 4.50237 11.1071 4.89289C11.4977 5.28342 11.4977 5.91658 11.1071 6.30711L9.41419 8.00005L11.1071 9.69299C11.4977 10.0835 11.4977 10.7167 11.1071 11.1072C10.7166 11.4977 10.0834 11.4977 9.69291 11.1072L7.99998 9.41426L6.30704 11.1072C5.91651 11.4977 5.28335 11.4977 4.89282 11.1072C4.5023 10.7167 4.5023 10.0835 4.89282 9.69299L6.58576 8.00005L4.89282 6.30711C4.50229 5.91658 4.50229 5.28342 4.89282 4.89289Z"
      fill="#010911"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7422 10.3436C12.7105 9.02232 13.1442 7.38412 12.9565 5.75678C12.7688 4.12944 11.9736 2.63297 10.7299 1.56676C9.48628 0.500552 7.88591 -0.0567652 6.249 0.006308C4.61209 0.0693811 3.05935 0.748193 1.90143 1.90694C0.743517 3.06568 0.0658159 4.61891 0.00391411 6.25587C-0.0579877 7.89282 0.500475 9.49279 1.56757 10.7357C2.63467 11.9786 4.13171 12.7727 5.75918 12.9592C7.38666 13.1457 9.02455 12.7109 10.3452 11.7416H10.3442C10.3742 11.7816 10.4062 11.8196 10.4422 11.8566L14.2922 15.7066C14.4797 15.8943 14.734 15.9997 14.9993 15.9998C15.2646 15.9999 15.519 15.8946 15.7067 15.7071C15.8943 15.5196 15.9998 15.2653 15.9999 15C16 14.7347 15.8947 14.4803 15.7072 14.2926L11.8572 10.4426C11.8214 10.4064 11.783 10.373 11.7422 10.3426V10.3436ZM12.0002 6.49963C12.0002 7.2219 11.8579 7.9371 11.5815 8.60439C11.3051 9.27168 10.9 9.878 10.3893 10.3887C9.87853 10.8994 9.27222 11.3046 8.60493 11.581C7.93763 11.8574 7.22244 11.9996 6.50017 11.9996C5.7779 11.9996 5.0627 11.8574 4.39541 11.581C3.72812 11.3046 3.1218 10.8994 2.61108 10.3887C2.10036 9.878 1.69523 9.27168 1.41883 8.60439C1.14243 7.9371 1.00017 7.2219 1.00017 6.49963C1.00017 5.04094 1.57963 3.64199 2.61108 2.61054C3.64253 1.57909 5.04148 0.999631 6.50017 0.999631C7.95886 0.999631 9.3578 1.57909 10.3893 2.61054C11.4207 3.64199 12.0002 5.04094 12.0002 6.49963Z"
      fill="#010911"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1573 7.55375C25.3525 7.35849 25.3525 7.0419 25.1573 6.84664C24.962 6.65138 24.6454 6.65138 24.4501 6.84664L15.9788 15.318L7.5535 6.89263C7.35824 6.69737 7.04166 6.69737 6.8464 6.89263C6.65114 7.08789 6.65114 7.40447 6.8464 7.59974L15.2717 16.0251L6.89213 24.4047C6.69686 24.5999 6.69686 24.9165 6.89213 25.1118C7.08739 25.307 7.40397 25.307 7.59923 25.1118L15.9788 16.7322L24.4044 25.1578C24.5997 25.353 24.9163 25.353 25.1115 25.1578C25.3068 24.9625 25.3068 24.6459 25.1115 24.4507L16.6859 16.0251L25.1573 7.55375Z"
      fill="#0B2FAC"
    />
  </svg>
);
