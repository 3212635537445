import { useEffect, useState } from 'react';
import cx from 'classnames';

import { ClearIcon, SearchIcon } from './Icons';

export interface CountryPrefixSelectorProps {
  onChange: (value: Option) => void;
  showSearchbox: boolean;
  open: boolean;
  options: Option[];
  placeholder: string;
}

export interface Option {
  id: CountryCode;
  countryName: string;
  prefix: string;
  isPreferred?: boolean;
}

const CountryPrefixSelector = ({
  onChange,
  open,
  options,
  placeholder,
  showSearchbox,
}: CountryPrefixSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  const searchOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSelectOptions(
      options.filter((option: Option) => {
        return event.target.value
          ? !!`${option.countryName} ${option.prefix}`
              .toLocaleLowerCase()
              .includes(event.target.value.toLocaleLowerCase())
          : option;
      }),
    );
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSelectOptions(options);
  };

  const renderOption = (option: Option) => (
    <div
      key={option.id}
      onClick={() => {
        onChange(option);
        clearSearch();
      }}
      className="flex items-center p-1 cursor-pointer text-GSdeep/900 hover:bg-GSblue/50"
    >
      <div className="px-2 w-8 h-4">
        <img
          width={16}
          height={16}
          loading="lazy"
          src={`/mpc-assets/flags/flag_${option.id}.svg`}
          alt={`${option.id} flag`}
          onError={(e) => {
            const imgElement = e.currentTarget as HTMLImageElement;

            if (!imgElement.dataset.fallback) {
              imgElement.dataset.fallback = 'true';
              imgElement.src = '/mpc-assets/flags/flag_placeholder.svg';
            }
          }}
        />
      </div>
      {option.countryName}
      <span className="font-italic text-GSdeep/300 ml-2">{option.prefix}</span>
    </div>
  );

  const anyPreferredOptions = selectOptions?.some(
    (option) => option.isPreferred,
  );

  return (
    <div
      className={cx(
        'absolute z-30 mt-1 box-content left-[-2px] w-full bg-white border-2 border-GSsteel/300 rounded',
        {
          blok: open,
          hidden: !open,
        },
      )}
    >
      {showSearchbox ? (
        <div className="flex flex-row items-center border-b border-GSsteel/300">
          <div className="pl-3 cursor-pointer">
            <SearchIcon />
          </div>
          <input
            type="text"
            onChange={searchOptions}
            value={searchTerm}
            placeholder={placeholder}
            className="flex-1 text-GSdeep/900 placeholder:text-GSsteel/500 focus:outline-none w-12 h-full py-3.5 px-2 bg-transparent"
          />
          {searchTerm.length ? (
            <div className="px-4 cursor-pointer" onClick={clearSearch}>
              <ClearIcon />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="max-h-[300px] overflow-auto dropdown-scrollbar">
        {anyPreferredOptions && (
          <div className="flex flex-col gap-2 bg-GSblue/50 border-y py-2 border-GSblue/100">
            {selectOptions
              ?.filter((option) => option.isPreferred)
              .map((option: Option) => renderOption(option))}
          </div>
        )}
        <div className="grid gap-2 py-2">
          {selectOptions
            .filter((option) => !option.isPreferred)
            .map((item) => renderOption(item))}
        </div>
      </div>
    </div>
  );
};

export default CountryPrefixSelector;
