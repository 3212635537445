import React, { FocusEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isSupportedCountry,
} from 'libphonenumber-js/max';
import {
  DATASOURCES_IDS,
  getDatasourceEntry,
} from '@/helpers/datasources/fetchDatasources';
import useResponsive from '@/helpers/layout/useResponsive';
import {
  getPrefixedNationalNumber,
  isPhoneValid,
} from '@/helpers/form/phoneHelpers';
import { SSGDataContext } from '@/helpers/contexts';
import { useClickOutside } from '../../helpers/layout/useClickOutside';
import CountryPrefixSelector from './CountryPrefixSelector';
import { ArrowDownIcon } from './Icons';
import PrefixesMobileOverlay from './PrefixesMobileOverlay';

export interface CountrySelectOption {
  id: CountryCode;
  countryName: string;
  prefix: string;
  isPreferred?: boolean;
}
export interface PhoneInputProps {
  disabled?: boolean;
  countries?: Array<CountryCode>;
  label: string;
  placeholder: string;
  searchFieldPlaceholder: string;
  searchPaneTitle: string;
  defaultCountry: string;
  error: string;
  onChange: (value: string) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  preferredCountries: Array<CountryCode>;
  isTutorForm?: boolean;
}

const defaultClasses =
  'bg-GSbase/white text-sm font-normal border-2 border-GSsteel/300 rounded';
const focusClasses =
  'focus-within:ring-GSblue/100 focus-within:ring-offset-0 focus-within:ring-4 focus-within:border-GSblue/500 focus-within:outline-none';

const PhoneInputField = ({
  disabled,
  countries,
  defaultCountry,
  label,
  placeholder,
  error,
  onChange,
  onBlur,
  preferredCountries = [],
  searchFieldPlaceholder,
  searchPaneTitle,
  isTutorForm = false,
}: PhoneInputProps) => {
  const dataSourcesContext = React.useContext(SSGDataContext);
  const [fullNumber, setFullNumber] = React.useState('');
  const { isMobile, isTablet } = useResponsive();
  const [countriesByCode, setCountriesByCode] = useState<
    Record<string, string>
  >(dataSourcesContext.countriesByCode || {});
  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const [countrySelectOptions, setCountrySelectOptions] = useState<
    CountrySelectOption[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<CountrySelectOption>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  useEffect(() => {
    const fetchDatasources = async () => {
      const pageLang = document.documentElement.lang;
      const datasources = await getDatasourceEntry(
        DATASOURCES_IDS.COUNTRIES,
        pageLang,
      );

      const countriesByCodeMap: Record<string, string> = Object.entries(
        datasources,
      ).reduce((ret: Record<string, string>, entry) => {
        const [key, value] = entry;
        // eslint-disable-next-line no-param-reassign
        ret[value] = key;
        return ret;
      }, {});

      setCountriesByCode(countriesByCodeMap);
    };
    fetchDatasources();
  }, []);

  useEffect(() => {
    let options: CountrySelectOption[];

    if (countries) {
      const validCountriesCodes = countries.filter((country) =>
        isSupportedCountry(country),
      );
      options = validCountriesCodes.map((country) => ({
        id: country,
        countryName: `${countriesByCode[country]}`,
        isPreferred: preferredCountries.includes(country),
        prefix: `+${getCountryCallingCode(country)}`,
      }));
    } else {
      options = getCountries().map((country) => ({
        id: country,
        countryName: `${countriesByCode[country]}`,
        isPreferred: false,
        prefix: `+${getCountryCallingCode(country)}`,
      }));

      setCountrySelectOptions(options);
    }

    setCountrySelectOptions(options);
  }, [countriesByCode, countries]);

  useEffect(() => {
    if (defaultCountry && countrySelectOptions) {
      setSelectedOption(
        countrySelectOptions.find((item) => item.id === defaultCountry),
      );
    }
  }, [defaultCountry, countrySelectOptions]);

  React.useEffect(() => {
    if (isPhoneValid(selectedOption?.prefix + phoneNumber)) {
      setFullNumber(
        getPrefixedNationalNumber(selectedOption?.prefix + phoneNumber),
      );
    } else {
      setFullNumber('');
    }
  }, [selectedOption, phoneNumber]);

  const dropdownSelectRef = useClickOutside(() => {
    if (!isMobile && !isTablet) {
      setCountrySelectOpen(false);
    }
  });

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setPhoneNumber(e.target.value);
      onChange(e.target.value);
      return;
    }

    const re = /^\d*$/;
    if (re.test(e.target.value)) {
      setPhoneNumber(e.target.value);
      onChange(selectedOption?.prefix + e.target.value);
    }
  };

  const handlePrefixChange = (item: CountrySelectOption) => {
    setSelectedOption(item);
    setCountrySelectOpen(false);
    onChange(item?.prefix + phoneNumber);
  };

  return (
    <div className="w-full flex flex-col gap-y-2 relative">
      <div className="w-full flex flex-col gap-y-1">
        {label && (
          <label
            className={cx('font-medium text-sm text-GSdeep/900', {
              'lg:text-base': isTutorForm,
            })}
            htmlFor="telephoneNumber"
          >
            {label}
          </label>
        )}
        <div
          className={cx(
            defaultClasses,
            focusClasses,
            'relative group/phone items-stretch flex',
            {
              'hover:border-GSblue/500': !disabled,
              'bg-GSsteel/50 border-GSsteel/100': disabled,
              'ring-GSblue/100 ring-offset-0 ring-4 border-GSblue/500 outline-none':
                countrySelectOpen,
              'border-GSblue/500': countrySelectOpen,
              '!border-GSfunctional/error': error.length,
            },
          )}
        >
          <>
            <div
              className={cx({
                'bg-GSsteel/50': defaultCountry,
              })}
              ref={dropdownSelectRef}
            >
              <div
                className={cx(
                  'flex flex-row h-full justify-center items-center p-2 gap-x-0.5',
                  { 'cursor-pointer': !disabled },
                )}
                onClick={() => {
                  if (countrySelectOptions.length > 1 && !disabled) {
                    setCountrySelectOpen(!countrySelectOpen);
                  }
                }}
              >
                <div className="p-1.5 w-8 h-8">
                  {selectedOption && (
                    <img
                      width={20}
                      height={20}
                      loading="eager"
                      src={`/mpc-assets/flags/flag_${selectedOption.id}.svg`}
                      alt={`${selectedOption.id} flag`}
                      onError={(e) => {
                        const imgElement = e.currentTarget as HTMLImageElement;

                        if (!imgElement.dataset.fallback) {
                          imgElement.dataset.fallback = 'true';
                          imgElement.src =
                            '/mpc-assets/flags/flag_placeholder.svg';
                        }
                      }}
                    />
                  )}
                </div>

                <div
                  className={cx(
                    'inline-flex flex-1 w-full text-base text-center text-GSdeep/900 bg-transparent pr-1',
                    { 'lg:text-base': isTutorForm },
                  )}
                >
                  {selectedOption?.prefix}
                </div>

                <div
                  className={cx({
                    'rotate-180': countrySelectOpen,
                  })}
                >
                  {countrySelectOptions.length > 1 && !disabled && (
                    <ArrowDownIcon />
                  )}
                </div>
              </div>
              {countrySelectOptions.length > 1 && !isMobile && !isTablet && (
                <CountryPrefixSelector
                  showSearchbox={countrySelectOptions.length > 7}
                  open={countrySelectOpen}
                  onChange={(item) => handlePrefixChange(item)}
                  options={countrySelectOptions}
                  placeholder={searchFieldPlaceholder}
                />
              )}
            </div>
            <div
              className={cx(
                'w-full border-l-2 border-GSsteel/300 focus-within:border-GSblue/500',
                {
                  'bg-GSsteel/50 border-GSsteel/100': disabled,
                  'group-hover/phone:border-GSblue/500': !disabled,
                  'border-GSblue/500': countrySelectOpen,
                  '!border-GSfunctional/error': error.length,
                },
              )}
            >
              <input
                type="tel"
                name="phone"
                id="phone"
                className="hidden"
                value={fullNumber}
                readOnly
                required
                data-leadin-telephone
              />
              <input
                type="tel"
                inputMode="numeric"
                placeholder={placeholder}
                className={cx(
                  'w-full h-full text-GSdeep/900 py-3 px-4 rounded-none focus:outline-none leading-5 self-center text-base',
                  {
                    'bg-white': !disabled,
                    'bg-GSsteel/50 border-GSsteel/100': disabled,
                    'border-GSblue/500': countrySelectOpen,
                    '!border-GSfunctional/error': error?.length,
                    'lg:text-base lg:py-4': isTutorForm,
                  },
                )}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onBlur={onBlur}
                disabled={disabled}
              />
            </div>
          </>
        </div>
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
      {(isMobile || isTablet) && (
        <PrefixesMobileOverlay
          isOpen={countrySelectOpen}
          onChange={(item) => handlePrefixChange(item)}
          closeModal={() => setCountrySelectOpen(false)}
          options={countrySelectOptions}
          label={searchPaneTitle}
          searchFieldPlaceholder={searchFieldPlaceholder}
        />
      )}
    </div>
  );
};

export default PhoneInputField;
